import { installationNoticeService } from '@/api';
import { Paging } from '@/api/base';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { PagingMixin } from '@/mixins/paging';
import { DeliveryModeEnum, InstallationNoticeStatusEnum, LogisticsOrderStatusEnum } from '@/resource/enum';
import {
  DeliveryShopItemRelList,
  InstallationNoticeInfo,
  InstallAttachmentsList,
  LogisticsList,
  ShopItemReList,
  ShopList,
  ShopListQuery
} from '@/resource/model';
import { TagsViewModule } from '@/store/modules/tags-view';
import {
  dateFormat,
  debounce,
  fuzzyQueryStr,
  messageError,
  messageErrors,
  showWarningConfirm,
  translation
} from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { Route } from 'vue-router';
import InstallAttachment from '../install-attachment/install-attachment.vue';
import AddInstallationOrder from './add-installation-order/add-installation-order.vue';

Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'InstallationNoticeDetail',
  components: {
    InstallAttachment,
    AddInstallationOrder
  }
})
export default class InstallationNoticeDetail extends mixins(PagingMixin, CustomColumnMixin) {
  public installation: InstallationNoticeInfo | null = null;

  public allowInstall: boolean = false;

  public currentDeliveryShopName = '';

  public currentDeliveryProduct: Array<ShopItemReList> = [];

  /**
   * 过滤后的数据，在没有任何筛选条件生效时，该数据必须保证与tableData一致
   */
  public filteredData: Array<Partial<ShopList>> = [];

  /**
   * 关联物流单数据列表
   */
  public logisticsOrders: Array<LogisticsList> = [];

  /**
   * 门店列表工具栏配置
   */
  public shopTableToolbarOptions: Array<OperationOption> = [];

  public shopTableOptions: OsTableOption<Partial<ShopList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public shopColumnOptions: Array<OsTableColumn<ShopList>> = [
    {
      prop: 'index',
      label: 'common.index',
      reserveSelection: true,
      minWidth: '50px',
      fixed: true
    },
    {
      prop: 'shopName',
      label: 'addInstallationNotice.shopName',
      minWidth: '150px',
      fixed: true,
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'addInstallationNotice.address',
      minWidth: '500px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return `${(row as ShopList).province}
        ${(row as ShopList).city ? (row as ShopList).city : ''}
        ${(row as ShopList).district ? (row as ShopList).district : ''}
        ${(row as ShopList).address}`;
      }
    },
    {
      prop: 'contacts',
      label: 'addInstallationNotice.contactsPerson',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'contactsTel',
      label: 'addInstallationNotice.contactsPhone',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishDay',
      label: 'addInstallationNotice.finishDay',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return dateFormat((row as ShopList).finishDay, 'yyyy-MM-DD');
      }
    },
    {
      prop: 'serveItems',
      label: 'addInstallationNotice.serviceItem',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'budgetAmount',
      label: 'addInstallationNotice.budgetAmount',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    }
  ];

  public shopPaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public shopTotal = 0;

  public productPaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public productTotal = 0;

  /**
   * 产品明细
   */
  public productTableOptions: OsTableOption<Partial<ShopItemReList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public productColumnOptions: Array<OsTableColumn<ShopItemReList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      fixed: true,
      reserveSelection: true
    },
    {
      prop: 'index',
      label: 'common.index',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      fixed: true,
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      minWidth: '300px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'projectProduct.visibleSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ShopItemReList).visibleWidth} × ${(row as ShopItemReList).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ShopItemReList).finishWidth} × ${(row as ShopItemReList).finishHeight}`;
      }
    },
    {
      prop: 'count',
      label: 'addInstallationNotice.installCount',
      minWidth: '180px'
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];
  /**
   * 门店明细查询条件
   */
  public queryForm: Partial<ShopListQuery> = {
    address: []
  };
  public queryItemsOption: Array<OsQueryItemOption<ShopListQuery>> = [
    {
      type: 'Slot',
      slotName: 'address',
      field: 'address',
      label: 'customer.area',
      option: {}
    }
  ];

  public logisticsTableOptions: OsTableOption<Partial<DeliveryShopItemRelList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    size: 'mini'
  };

  public logisticsColumnOptions: Array<OsTableColumn<LogisticsList>> = [
    {
      prop: 'code',
      label: 'logistics.code',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'deliveryShopName',
      label: 'logistics.relevanceShop',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'childCount',
      label: 'logistics.childCount',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'status',
      label: 'logistics.status',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'sendMethod',
      label: 'logistics.logisticsInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'receiver',
      label: 'logistics.receiverInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'sender',
      label: 'logistics.senderInfo',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'createUserName',
      label: 'common.createUser',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as LogisticsList).createTime);
      }
    }
  ];

  public logisticsPaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public logisticsTotal = 0;

  public selectedShopRows: Array<ShopList> = [];

  public selectInstallAttachmentRows: Array<InstallAttachmentsList> = [];

  public addInstallOrderVisible = false;

  private needReload = false;

  private readonly importPath = 'platform/import/installation/';

  private id = Number(this.$route.query.id);

  public get installNoticeStatus(): string {
    return translation(`installationNoticeStatus.${InstallationNoticeStatusEnum[this.installation!.status]}`);
  }

  /**
   * 是否允许提交
   */
  public get allowPush(): boolean {
    return this.installation!.status === InstallationNoticeStatusEnum.new;
  }

  /**
   * 是否允许审核
   */
  public get allowConfirm(): boolean {
    return this.installation!.status === InstallationNoticeStatusEnum.waitingTake;
  }

  /**
   * 是否允许反审核
   */
  public get allowReject(): boolean {
    return this.installation!.status === InstallationNoticeStatusEnum.waitingInstall;
  }

  /**
   * 是否允许新建安装订单
   */
  public get allowNewInstallOrder(): boolean {
    const allowStatus = [InstallationNoticeStatusEnum.waitingInstall, InstallationNoticeStatusEnum.partialInstall];

    return allowStatus.includes(this.installation!.status) && this.allowInstall;
  }

  /**
   * 同步filteredData 和 tableData的数据
   */
  public synchronizationData(): void {
    this.filteredData = this.installation?.shopList || [];
  }

  public filterData(): void {
    this.synchronizationData();
    if (this.queryForm.address![0]) {
      this.filteredData = this.filteredData.filter(x => fuzzyQueryStr(this.queryForm.address![0]!, x.province!));
    }
    if (this.queryForm.address![1]) {
      this.filteredData = this.filteredData.filter(x => fuzzyQueryStr(this.queryForm.address![1]!, x.city!));
    }
    if (this.queryForm.address![2]) {
      this.filteredData = this.filteredData.filter(x => fuzzyQueryStr(this.queryForm.address![2]!, x.district!));
    }

    this.filteredData.forEach((item, index) => {
      item.index = index + 1;
    });
    this.shopPagingData();
  }

  public shopPagingData(): void {
    this.shopTotal = this.filteredData.length;
    this.shopTableOptions.data = [];
    this.shopTableOptions.data.push(
      ...this.filteredData.slice(
        (this.shopPaging.currentPage - 1) * this.shopPaging.showCount,
        this.shopPaging.currentPage * this.shopPaging.showCount
      )
    );

    if (this.shopTotal > 0) {
      this.shopClick(this.shopTableOptions.data[0] as any);
    }
  }

  public activated(): void {
    this.needReload = false;
    if (!isNaN(this.id)) {
      this.id = Number(this.$route.query.id);
      this.loadDetails(this.id);
    }
  }

  public created(): void {
    this.initColumns(this.shopColumnOptions, 'install-notice-shop');
    if (!isNaN(this.id)) {
      this.loadDetails(this.id);
    }
  }

  /**
   * 表格行选中事件
   */
  public handleShopSelectionChange(selectedData: Array<ShopList>): void {
    this.selectedShopRows = selectedData;
  }

  public shopClick(deliveryShop: ShopList): void {
    deliveryShop.shopItemRelList.forEach((item, index) => {
      item.index = index + 1;
    });
    this.currentDeliveryProduct = deliveryShop.shopItemRelList || [];
    this.currentDeliveryShopName = ` —— ${deliveryShop.shopName}`;
    this.productPagingData();
  }

  public productPagingData(): void {
    this.productTotal = this.currentDeliveryProduct.length;
    this.productTableOptions.data = this.currentDeliveryProduct.slice(
      (this.productPaging.currentPage - 1) * this.productPaging.showCount,
      this.productPaging.currentPage * this.productPaging.showCount
    );
  }

  /**
   * 表格行选中事件
   */
  public handleFileSelectionChange(selectedData: Array<InstallAttachmentsList>): void {
    this.selectInstallAttachmentRows = selectedData;
  }

  /**
   * 提交安装通知单
   */
  public installationNoticePush(): void {
    installationNoticeService
      .batchSubmit([this.installation!.id])
      .then(() => {
        Message.success(translation('operationRes.operationSuccess'));
        this.needReload = true;
        this.loadDetails(this.id);
      })
      .catch(error => {
        messageErrors(error);
      });
  }

  /**
   * 安装通知单接单
   */
  public async installationNoticeConfirm(): Promise<void> {
    try {
      await showWarningConfirm(translation('installationNotice.confirmTake'));
      await installationNoticeService.batchAudit([this.installation!.id]);
      Message.success(translation('operationRes.operationSuccess'));
      this.needReload = true;
      this.loadDetails(this.id);
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.operationCanceled'));
      } else {
        messageErrors(error);
      }
    }
  }

  /**
   * 安装通知单反审核（即取消接单）
   */
  public async installationNoticeReject(): Promise<void> {
    try {
      await showWarningConfirm(translation('installationNotice.confirmReject'));
      await installationNoticeService.batchReject([this.installation!.id]);
      Message.success(translation('operationRes.operationSuccess'));
      this.needReload = true;
      this.loadDetails(this.id);
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.operationCanceled'));
      } else {
        messageErrors(error);
      }
    }
  }

  // public openAddInstallOrderDialog(): void {
  //   this.addInstallOrderVisible = true;
  // }

  public linkToDetails(): void {
    this.$router.push({
      path: '/add-installation-order',
      query: {
        id: this.installation!.id.toString()
      }
    });
  }

  public redirectToOrderList(): void {
    this.$router.push('installation-order');
    const orderRoute = TagsViewModule.visitedViews.find(x => x.path === '/installation-order');
    if (orderRoute) {
      orderRoute.meta!.needReload = true;
    }
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    if (to.path === '/installation-notice') {
      to.meta!.needReload = this.needReload;
    }
    next();
  }

  public logisticsPagingData(): void {
    this.logisticsTotal = this.logisticsOrders.length ?? 0;
    this.logisticsTableOptions.data =
      this.logisticsOrders.slice(
        (this.logisticsPaging.currentPage - 1) * this.logisticsPaging.showCount,
        this.logisticsPaging.currentPage * this.logisticsPaging.showCount
      ) ?? [];
  }

  /**
   * 获取物流单状态class
   * @param logistics 物流单
   * @returns
   */
  public getLogisticsStatusClass(logistics: LogisticsList): string {
    switch (logistics.status) {
      case LogisticsOrderStatusEnum.new:
        return 'info-dot';
      case LogisticsOrderStatusEnum.inTransit:
        return 'primary-dot';
      case LogisticsOrderStatusEnum.closed:
        return 'success-dot';
      default:
        return 'danger-dot';
    }
  }

  /**
   * 获取物流单状态名称
   * @param logistics 物流单
   * @returns
   */
  public getLogisticsStatusName(logistics: LogisticsList): string {
    if (!LogisticsOrderStatusEnum[logistics.status]) {
      return 'common.unKnownStatus';
    }
    return `logisticsOrderStatus.${LogisticsOrderStatusEnum[logistics.status]}`;
  }

  public getLogisticsReceiverAdress(logistics: LogisticsList): string {
    return `${logistics.receivingProvince ?? ''}${logistics.receivingCity ?? ''} ${logistics.receivingDistrict ??
      ''}${logistics.receivingAddress ?? ''}`;
  }

  public getLogisticsSenderAdress(logistics: LogisticsList): string {
    return `${logistics.sendProvince ?? ''}${logistics.sendCity ?? ''}${logistics.sendDistrict ??
      ''}${logistics.senderAddress ?? ''}`;
  }

  public linkToLogisticsDetails(logistics: LogisticsList): void {
    this.$router.push({
      path: 'logistics-details',
      query: {
        id: logistics.id.toString()
      }
    });
  }

  public getDeliveryMode(mode: DeliveryModeEnum): string {
    return `deliveryMode.${DeliveryModeEnum[mode]}`;
  }

  @debounce()
  private loadDetails(id: number): void {
    // 清空查询条件
    this.queryForm.address = [];
    installationNoticeService
      .getById(id)
      .then(res => {
        this.installation = res;
        this.installation!.installAttachmentList = this.installation.installAttachmentList ?? [];
        this.$nextTick(() => {
          (this.$refs.installAttachment as InstallAttachment).tableSelectAll();
        });
        this.getInstallFlag(id);
        this.filterData();
        this.getLogisticsOrders(id);
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 获取安装通知单门店关联物流单
   * @param id 安装通知单ID
   */
  private getLogisticsOrders(id: number): void {
    installationNoticeService
      .getLogisticsOrders(id)
      .then(res => {
        this.logisticsOrders = res;
        this.logisticsTotal = res.length;
        this.logisticsPagingData();
      });
  }

  /**
   * 是否有可安装门店
   * @param id
   * @returns
   */
  private async getInstallFlag(id: number): Promise<void> {
    try {
      const info = await installationNoticeService.getInfo(id);
      this.allowInstall = info.shopList.length > 0;
    } catch (error) {
      messageError(error);
    }
  }
}
